<template>
  <div class="auth-page">
    <div class="container-fluid p-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
          <div class="row justify-content-center g-0">
            <div class="col-12">
              <div class="mt-5">
                <div class="card mb-0">
                  <div class="card-body">
                    <div class="auth-full-page-content rounded d-flex p-3 my-2">
                      <div class="w-100">
                        <div class="d-flex flex-column h-100">
                          <div class="mb-4 mb-md-5">
                            <router-link :to="'/' + this.$route.params.slug" class="d-block auth-logo">
                              <span class="d-flex justify-content-center">
                                <img :src="company?.imageUrl ? company.imageUrl : ''" alt="Logo" height="28"/>
                              </span>
                            </router-link>
                          </div>
                          <div class="auth-content my-auto">
                            <div class="text-center">
                              <h5 class="mb-0">Cadastre-se</h5>
                              <p class="text-muted mt-2">
                                Fique sempre por dentro das melhores ofertas da
                                internet.
                              </p>
                            </div>

                            <form class="mt-4 pt-2" @submit.prevent="onRegister">
                              <div class="form-floating form-floating-custom mb-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input-username"
                                  placeholder="Nome"
                                  v-model="name"
                                />
                                <label for="input-username">*Nome</label>
                                <div class="form-floating-icon">
                                  <eva-icon name="people-outline"></eva-icon>
                                </div>
                              </div>

                              <div class="form-floating form-floating-custom mb-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="input-username"
                                  placeholder="E-mail"
                                  v-model="email"
                                  @input="convertToLowerCase"
                                />
                                <label for="input-username">*E-mail</label>
                                <div class="form-floating-icon">
                                  <eva-icon name="email-outline"></eva-icon>
                                </div>
                              </div>

                              <div class="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                <input
                                  :type="showPassword ? 'text' : 'password'"
                                  class="form-control pe-5"
                                  id="password-input"
                                  placeholder="Senha"
                                  v-model="password"
                                />

                                <button
                                  type="button"
                                  class="btn btn-link position-absolute h-100 end-0 top-0"
                                  id="password-addon"
                                  @click="toogleVisiblePassword"
                                >
                                  <i class="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                </button>
                                
                                <label for="password-input">*Senha</label>
                                
                                <div class="form-floating-icon">
                                  <eva-icon name="lock-outline"></eva-icon>
                                </div>
                              </div>

                              <div class="mb-3 d-flex justify-content-center">
                                <div
                                  v-if="isLoading"
                                  :class="`spinner-border m-1 text-${company.color ? company.color : 'primary'}`"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                                <button
                                  v-else
                                  :class="`btn btn-rounded w-100 waves-effect waves-light btn-${company.color ? company.color : 'primary'}`"
                                  type="submit"
                                >
                                  <span>Criar cadastro</span>
                                </button>
                              </div>
                            </form>

                            <div class="mt-4 pt-3 text-center">
                              <p class="text-muted mb-0">
                                Já tem cadastro ?
                                <router-link
                                  :to="`/${$route.params.slug}/login`"
                                  :class="`fw-semibold text-${company.color ? company.color : 'primary'}`"
                                >
                                  Entrar
                                </router-link>
                              </p>
                            </div>
                          </div>
                          <div class="mt-4 text-center">
                            <p class="mb-0">
                              © {{ new Date().getFullYear() }} Shopping Links .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end auth full page content -->
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container fluid -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      name: "",
      email: "",
      password: "",
      showPassword: false,
    };
  },
  computed: {
    ...mapState(["isLoading", "isAuthenticated"]),
  },
  methods: {
    ...mapActions(["setLoading", "setAuthenticated"]),
    convertToLowerCase() {
      this.email = this.email.toLowerCase();
    },
    toogleVisiblePassword() {
      this.showPassword = !this.showPassword;
    },
    onRegister() {
      if (!this.name || this.name.length < 3) {
        this.$customAlert({
          type: "warning",
          msg: "Informe um nome",
        });
        return;
      }
      if (!this.$utils.emailValidation(this.email)) return;
      if (!this.$utils.validatePassword(this.password)) return;

      const payload = {
        idCompany: this.company._id,
        name: this.name,
        email: this.email,
        password: this.password,
        type: 2,
      };

      this.setLoading(true);
      this.$api
        .post("user", payload, null, false)
        .then(() => {
          const payload = {
            email: this.email,
            password: this.password,
            idCompany: this.company._id,
          };
          this.$auth
            .login(payload)
            .then(() => {
              this.setLoading(false);
              this.$router.push({ name: "companyPrincipal" });
            })
            .catch((err) => {
              this.setLoading(false);
              this.$customAlert({
                type: "error",
                msg: err?.data?.message || "Erro na autenticação",
              });
            });
        })
        .catch((err) => {
          this.setLoading(false);
          this.$customAlert({
            type: "error",
            msg: err?.data?.message || "Erro ao criar usuário",
          });
        });
    },
  },
};
</script>
